const ServicesSection = () => {
  return (
    <div
      id="services-section"
      className="p-5 md:px-20 flex flex-col items-center w-full md:w-[1000px] mx-auto"
    >
      <p className="text-[30px] font-medium font_pp_gothic text-center">
        Our Services
      </p>
      <div className="mt-5 bg-black rounded-xl pb-5 md:pb-0 px-5 md:px-20 md:flex md:space-x-16">
        <div className="flex justify-center">
          <img
            src="/images/booking_image.png"
            alt="booking_image"
            width={390}
            height={390}
            className="object-contain w-[190px] md:w-[400px] mb-10 md:mb-16"
          />
        </div>
        <div className="flex flex-col md:justify-center">
          <p className="text-white font-bold text-xl">Errand Booking</p>
          <p className="text-white">
            Whether you're a busy professional, a parent juggling multiple
            responsibilities, or simply looking to simplify your daily routine,
            Errango is your ultimate solution.
          </p>
        </div>
      </div>
      <div className="md:grid grid-cols-2 mt-5 gap-5">
        <div className="flex flex-col px-10 pt-10 rounded-2xl bg-primary">
          <p className="text-white font-bold text-xl">Pay Utility Bills</p>
          <p className="text-white">
            Use extra funds earned to pay for utility bills and send payments
            within the app.
          </p>
          <div className="grow"></div>
          <div className="flex justify-center">
            <img
              src="/images/utility_image.png"
              alt="utility"
              width={190}
              height={390}
              className="object-contain mt-20 md:mt-5"
            />
          </div>
        </div>
        <div className="px-10 pt-10 mt-10 md:mt-0 rounded-2xl bg-[#E6E6E6]">
          <p className="font-bold text-xl">Unified Wallet System</p>
          <p className="">
            Get a unified account number and POS card for your errand
            withdrawals and transactions.
          </p>
          <div className="flex justify-center">
            <img
              src="/images/wallet_image.png"
              alt="wallet"
              width={190}
              height={390}
              className="object-contain mt-20 md:mt-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
