import { Link } from "react-router-dom";

const DownloadSection = () => {
  return (
    <div className="py-10 md:py-20 px-5 md:px-16 flex flex-col items-center w-full md:w-[1000px] mx-auto">
      <div className="rounded-xl px-5 md:px-20 pt-10 md:grid grid-cols-2 download_section">
        <div className="flex flex-col justify-center">
          <p className="text-white font-medium font_pp_gothic text-[25px] text-center">
            One Solution for All Your <br />
            Errand Needs
          </p>
          <div className="mt-5 flex space-x-5 mx-auto">
            <Link to="#">
              <img
                src="/icons/apple_icon.svg"
                alt="apple"
                width={160}
                height={47}
                className="object-contain"
              />
            </Link>
            <Link to="#">
              <img
                src="/icons/google_icon.svg"
                alt="google"
                width={160}
                height={47}
                className="object-contain"
              />
            </Link>
          </div>
        </div>
        <div className="flex justify-center mt-10 md:mt-0">
          <img
            src="/images/download.png"
            alt="download"
            width={260}
            height={300}
            className="object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadSection;
