import React from "react";

const BrandSection = () => {
  return (
    <div className="md:max-w-screen-lg mx-auto px-16 mb-10 flex space-x-10 overflow-x-auto items-center justify-between">
      <img
        src="/images/sterling.png"
        alt="sterling"
        width={150}
        height={30}
        className="object-contain"
      />
      <img
        src="/images/readycash.png"
        alt="readycash"
        width={150}
        height={30}
        className="object-contain"
      />
      <img
        src="/images/wema.png"
        alt="wema"
        width={150}
        height={30}
        className="object-contain"
      />
      <img
        src="/images/bank3d.png"
        alt="bank3d"
        width={150}
        height={30}
        className="object-contain"
      />
    </div>
  );
};

export default BrandSection;
