import { Link } from "react-router-dom";

const BecomeSection = () => {
  return (
    <div className="become_section flex flex-col">
      <div className="md:max-w-screen-lg mx-auto mt-5 md:mt-0 p-5 md:p-16 md:grid grid-cols-2 gap-5">
        <div className="flex flex-col justify-center">
          <p className="text-[20px] md:text-[30px] font-medium font_pp_gothic">
            Become an Errango Runner
          </p>
          <p className="mt-7 text-justify text-[#4d4d4d] font-light">
            Are you tired of juggling your to-do list, rushing around town, and
            feeling overwhelmed by endless errands? Look no further , Errango
            App is here to simplify your life and give you back the precious
            gift of time. Experience the joy of stress-free living. Say goodbye
            to errand chaos and hello to efficiency and convenience. Welcome to
            a life where you have time for what truly matters!
          </p>
          <div className="mt-5 flex items-center space-x-5">
            <Link to="#">
              <img
                src="/icons/apple_icon_2.svg"
                alt="apple"
                width={148}
                height={42}
                className="object-contain"
              />
            </Link>
            <Link to="#">
              <img
                src="/icons/google_icon_2.svg"
                alt="google"
                width={148}
                height={42}
                className="object-contain"
              />
            </Link>
          </div>
        </div>
        <div className="flex mt-5 md:mt-20 items-center justify-center">
          <img
            src="/images/become_image.png"
            alt="google"
            width={520}
            height={480}
            className="object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default BecomeSection;
