const YoutubeSection = () => {
  return (
    <div
      id="youtube-section"
      className="p-5 mt-5 md:mt-0 md:p-20 flex flex-col items-center"
    >
      <p className="text-[20px] md:text-[30px] font-medium font_pp_gothic text-center">
        Experience Seamless Errand Service
      </p>
      <p className="text-center text-[#4d4d4d] font-light mt-5">
        Trusted service providers who can complete your errands for you
      </p>
      <img
        src="/images/youtube.png"
        alt="youtube"
        width={900}
        height={510}
        className="object-contain mt-10"
      />
    </div>
  );
};

export default YoutubeSection;
