import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Navbar = () => {
  return (
    <div>
      <div className="w-full md:max-w-screen-xl flex justify-between items-center py-2 px-6 md:py-4 md:px-4 mx-auto">
        <Link to="#">
          <img
            src="/icons/logo.svg"
            alt="logo"
            width={148}
            height={34}
            className="object-contain w-[100px] md:w-[148px]"
          />
        </Link>

        <div className="flex items-center">
          <ul className="font-medium flex space-x-8 text-white">
            <li className="hidden md:block">
              <ScrollLink
                to="youtube-section"
                spy={true}
                smooth={true}
                offset={30} // adjust the offset based on your page layout
                duration={500}
                className="py-2 pl-3 pr-4 hover:decoration-primary hover:underline hover:underline-offset-8"
              >
                About Us
              </ScrollLink>
            </li>
            <li className="hidden md:block">
              <ScrollLink
                to="services-section"
                spy={true}
                smooth={true}
                offset={-20} // adjust the offset based on your page layout
                duration={500}
                className="py-2 pl-3 pr-4 hover:decoration-primary  hover:underline hover:underline-offset-8"
              >
                Our Services
              </ScrollLink>
            </li>
            <li className="hidden md:block">
              <ScrollLink
                to="contact-section"
                spy={true}
                smooth={true}
                offset={-60} // adjust the offset based on your page layout
                duration={500}
                className="py-2 pl-3 pr-4 hover:decoration-primary  hover:underline hover:underline-offset-8"
              >
                Contact Us
              </ScrollLink>
            </li>
          </ul>

          <div className="ml-10">
            <Link
              to="#"
              className="text-white md:bg-primary hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
            >
              Download Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
