import { Link } from "react-router-dom";

const ScheduleSection = () => {
  return (
    <div className="become_section flex flex-col">
      <div className="md:max-w-screen-lg mx-auto p-5 mt-5 md:mt-0 md:p-16 md:grid grid-cols-2 gap-5">
        <div className="flex flex-col justify-center">
          <p className="text-[20px] md:text-[30px] font-medium font_pp_gothic">
            Set Your Own Schedule
          </p>
          <p className="mt-7 text-justify font-light text-[#4d4d4d]">
            Run errands all day or run just one, it is up to you! set up your
            availability and get instant notifications of errands around you and
            make money at your comfort.
          </p>
          <div className="mt-5 flex items-center space-x-5">
            <Link to="#">
              <img
                src="/icons/apple_icon_2.svg"
                alt="apple"
                width={148}
                height={42}
                className="object-contain"
              />
            </Link>
            <Link to="#">
              <img
                src="/icons/google_icon_2.svg"
                alt="google"
                width={148}
                height={42}
                className="object-contain"
              />
            </Link>
          </div>
        </div>
        <div className="flex mt-20 items-center justify-center">
          <img
            src="/images/phone.png"
            alt="phone"
            width={280}
            height={560}
            className="object-contain h-[320px] md:h-[560px]"
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleSection;
