import React from "react";
import FAQItem from "./FAQItem";

const FAQSection = () => {
  return (
    <div className="md:max-w-screen-lg mx-auto p-5 md:p-16 flex flex-col items-center">
      <p className="text-primary text-[14px]">FAQ'S</p>
      <p className="font-medium font_pp_gothic text-[28px] md:text-[40px]">
        Got questions?
      </p>
      <p className="mb-10 text-center">
        Get the answers to your questions about Errango.
      </p>
      <FAQItem
        title="How does Errango App work?"
        description="Errango is a platform that allows you to effortlessly manage your
          errands and tasks with a few taps on your smartphone with easy and
          wallet system."
      />
      <FAQItem
        title="Is it safe to use Errango?"
        description="Errango is a platform that allows you to effortlessly manage your
          errands and tasks with a few taps on your smartphone with easy and
          wallet system."
      />
      <FAQItem
        title="Can I track my errand?"
        description="Errango is a platform that allows you to effortlessly manage your
          errands and tasks with a few taps on your smartphone with easy and
          wallet system."
      />
      <FAQItem
        title="Are there any hidden fees with Errango?"
        description="Errango is a platform that allows you to effortlessly manage your
          errands and tasks with a few taps on your smartphone with easy and
          wallet system."
      />
    </div>
  );
};

export default FAQSection;
