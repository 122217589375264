import { useState } from "react";

const FAQItem = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-5 w-full md:w-[700px]">
      <div className="flex justify-between">
        <p className="font-medium font_pp_gothic text-[18px]">{title}</p>
        <img
          src={isOpen ? "/icons/chevron-down.svg" : "/icons/chevron-up.svg"}
          alt="up"
          width={24}
          height={24}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="object-contain"
        />
      </div>
      {isOpen ? <p className="text=[#3a3a3a] mt-2">{description}</p> : <></>}
    </div>
  );
};

export default FAQItem;
