import Navbar from "./Navbar";
import { Link } from "react-router-dom";

const TopSection = () => {
  return (
    <div className="top_section flex flex-col">
      <Navbar />
      <p className="px-10 md:w-[700px] leading-10 mt-16 text-white text-center text-[25px] md:text-[50px]  font-medium font_pp_gothic mx-auto">
        Easy hassle free way to make money
      </p>
      <p className="w-full md:max-w-screen-xl mt-5 text-[#b3b3b3] text-[13px] md:text-base text-center mx-auto">
        Become an errand runner and make 10k daily, set your <br />
        own schedule and fill in the gabs the Uber can't.
      </p>
      <div className="mt-16 md:max-w-screen-xl hidden md:flex items-center space-x-5 mx-auto">
        <Link to="#">
          <img
            src="/icons/apple_icon.svg"
            alt="apple"
            width={148}
            height={42}
            className="object-contain"
          />
        </Link>
        <Link to="#">
          <img
            src="/icons/google_icon.svg"
            alt="google"
            width={148}
            height={42}
            className="object-contain"
          />
        </Link>
      </div>
      <div className="mt-16 md:max-w-screen-xl mx-auto">
        <img
          src="/images/app_images.png"
          alt="apple"
          width={1140}
          height={340}
          className="object-contain hidden md:block"
        />

        <img
          src="/images/mobile_top_image.png"
          alt="apple"
          width={1140}
          height={340}
          className="object-contain md:hidden"
        />
      </div>
    </div>
  );
};

export default TopSection;
