import { Link } from "react-router-dom";

const ContactSection = () => {
  return (
    <div
      id="contact-section"
      className="w-full md:w-[1000px] mx-auto px-5 md:px-16 md:grid grid-cols-2 rounded-2xl"
    >
      <img
        src="/images/call.png"
        alt="call"
        width={148}
        height={42}
        className="object-contain w-fit h-fit rounded-t-2xl md:rounded-l-2xl"
      />
      <div className="p-10 rounded-b-2xl md:rounded-r-2xl border flex flex-col justify-center">
        <p className="font-medium text-[24px] font_pp_gothic">Say Hello</p>
        <form class="max-w-sm mt-10">
          <div class="mb-5">
            <label
              for="full_name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Full name
            </label>
            <input
              type="text"
              id="full_name"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
              placeholder="Enter your full name"
              required
            />
          </div>
          <div class="mb-5">
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
              placeholder="Enter your email address"
              required
            />
          </div>
          <div class="mb-5">
            <label
              for="message"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Message
            </label>
            <textarea
              type="message"
              id="message"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
              placeholder="Enter your email address"
              required
            />
          </div>
        </form>
        <Link
          to="#"
          className="text-white mt-5 bg-primary hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center"
        >
          Submit Now
        </Link>
      </div>
    </div>
  );
};

export default ContactSection;
