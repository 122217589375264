import BecomeSection from "../components/BecomeSection";
import BrandSection from "../components/BrandSection";
import ContactSection from "../components/ContactSection";
import DownloadSection from "../components/DownloadSection";
import FAQSection from "../components/FAQSection";
import Footer from "../components/Footer";
import ScheduleSection from "../components/ScheduleSection";
import ServicesSection from "../components/ServicesSection";
import TopSection from "../components/TopSection";
import YoutubeSection from "../components/YoutubeSection";

const Home = () => {
  return (
    <div>
      <TopSection />
      <BecomeSection />
      <YoutubeSection />
      <ServicesSection />
      <ScheduleSection />
      <FAQSection />
      <ContactSection />
      <DownloadSection />
      <BrandSection />
      <Footer />
    </div>
  );
};

export default Home;
