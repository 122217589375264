import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Footer = () => {
  return (
    <div className="bg-black p-16">
      <div className="md:max-w-screen-xl flex md:flex-row flex-col justify-between items-center py-2 px-6 md:py-4 md:px-4 mx-auto">
        <Link to="#">
          <img
            src="/icons/logo.svg"
            alt="logo"
            width={148}
            height={34}
            className="object-contain"
          />
        </Link>

        <div className="flex items-center my-5 md:my-0 ">
          <ul className="font-medium flex space-x-3 md:space-x-8 text-white">
            <li className="">
              <ScrollLink
                to="youtube-section"
                spy={true}
                smooth={true}
                offset={30} // adjust the offset based on your page layout
                duration={500}
                className="py-2 md:pl-3 md:pr-4 hover:decoration-primary hover:underline hover:underline-offset-8 text-nowrap"
              >
                About Us
              </ScrollLink>
            </li>
            <li className="">
              <ScrollLink
                to="services-section"
                spy={true}
                smooth={true}
                offset={-20} // adjust the offset based on your page layout
                duration={500}
                className="py-2 md:pl-3 md:pr-4 hover:decoration-primary  hover:underline hover:underline-offset-8 text-nowrap"
              >
                Our Services
              </ScrollLink>
            </li>
            <li className="">
              <ScrollLink
                to="contact-section"
                spy={true}
                smooth={true}
                offset={-60} // adjust the offset based on your page layout
                duration={500}
                className="py-2 md:pl-3 md:pr-4 hover:decoration-primary  hover:underline hover:underline-offset-8 text-nowrap"
              >
                Contact Us
              </ScrollLink>
            </li>
          </ul>
        </div>

        <div className="flex space-x-3">
          <img
            src="/icons/facebook.svg"
            alt="facebook"
            width={24}
            height={24}
            className="object-contain"
          />
          <img
            src="/icons/instagram.svg"
            alt="instagram"
            width={24}
            height={24}
            className="object-contain"
          />
          <img
            src="/icons/twitter.svg"
            alt="twitter"
            width={24}
            height={24}
            className="object-contain"
          />
          <img
            src="/icons/linkedin.svg"
            alt="linkedin"
            width={24}
            height={24}
            className="object-contain"
          />
        </div>
      </div>
      <hr className="my-5" />
      <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 justify-center items-center py-2 md:py-4 md:px-4 mx-auto">
        <span class="text-sm text-gray-500 text-center text-nowrap">
          © 2023 Errango™. All Rights Reserved.
        </span>
        <div className="">
          <ul className="font-medium flex flex-col md:flex-row space-y-3 md:space-y-0 text-sm text-gray-500 items-center justify-center">
            <li className="">
              <Link
                to=""
                className="py-2 md:pl-3 md:pr-4 hover:decoration-primary hover:underline hover:underline-offset-8 text-center"
              >
                Privacy Policy
              </Link>
            </li>
            <li className="">
              <Link
                to=""
                className="py-2 md:pl-3 md:pr-4 hover:decoration-primary hover:underline hover:underline-offset-8 text-center"
              >
                Terms of Service
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
